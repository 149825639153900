html,
body {
    margin: 0;
    padding: 0;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    // 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

a {
    text-decoration: none;
}

.grecaptcha-badge {
    visibility: hidden !important;
}
